import React from 'react'
import { Link } from 'react-router-dom'

import { useUser } from '../hooks'

export function UserLink({
  id,
  showId,
  showEmail = true,
  title,
  newTab,
}: {
  id: string
  showId?: boolean
  showEmail?: boolean
  title?: string
  newTab?: boolean
}) {
  const { data: user, isSuccess } = useUser(id, { enabled: showEmail, refetchOnMount: false })
  let text = id
  if (isSuccess) {
    let email = ''
    if (showEmail) {
      if (user.email) {
        email = user.email
      }
    }
    if (showId && showEmail) {
      text = `${email} (${id})`
    } else if (showEmail) {
      text = `${email}`
    }
  }
  return (
    <Link
      target={newTab ? '_blank' : '_self'}
      style={{ textDecoration: 'underline' }}
      to={`/accounts/users/${id}`}
      title={title}
    >
      {text}
    </Link>
  )
}
