import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useColorMode,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { MdMenu } from '@react-icons/all-files/md/MdMenu'
import { useQueryClient } from '@tanstack/react-query'
import { type FeatureFlags, useFeatureFlags } from '@wanda/shared/src/hooks/useFeatureFlags'
import { useWarehouses } from '@wanda/shared/src/hooks/useWarehouses'
import { ascend, groupBy, indexBy, prop, propOr, sortBy, sortWith, values } from 'ramda'
import React, { Fragment, Suspense, useEffect } from 'react'
import {
  MdBuild,
  MdCheckBoxOutlineBlank,
  MdDirectionsCar,
  MdErrorOutline,
  MdForklift,
  MdHome,
  MdListAlt,
  MdOutbox,
  MdOutlineOpenInNew,
  MdOutlineWarehouse,
  MdShelves,
  MdShoppingCart,
  MdSystemUpdateAlt,
  MdTrolley,
} from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import {
  type AccountDto,
  UserRole,
  type UserV2Dto,
  formatCity,
  requireRoles,
  useCurrentAccount,
  useRoles,
} from '@wanda/shared'
import Loader from 'components/ui/Loader'
import { SupportedCities } from 'consts'
import { useAppSelector } from 'hooks/useAppSelector'
import { useLogout } from 'hooks/useCurrentUser'
import { sentenceCase } from 'sentence-case'
import { setCity, setWarehouse } from 'store/filters'
import { setPage as setOrdersPage } from 'store/orders'
import { setPage as setRoutesPage } from 'store/routes'

enum RouteGroup {
  HUB = 'HUB',
  WAREHOUSE = 'WAREHOUSE',
  TOOLS = 'TOOLS',
  DRIVER = 'DRIVER',
  OTHERS = 'OTHERS',
}

const routeSortOrder = new Map<RouteGroup, number>([
  [RouteGroup.OTHERS, 1],
  [RouteGroup.HUB, 2],
  [RouteGroup.DRIVER, 3],
  [RouteGroup.WAREHOUSE, 4],
  [RouteGroup.TOOLS, 5],
])

export type Route = {
  icon: React.ReactNode
  path: string
  text: string
  group?: RouteGroup
  showSearch: boolean
  showWarehouseSelector?: boolean
  featureFlag?: FeatureFlags
  roles: UserRole[]
}
const home: Route = {
  icon: <MdHome />,
  path: '/',
  text: 'Home',
  showSearch: false,
  roles: [],
}

export const hubRoutes = [
  {
    icon: <MdListAlt />,
    path: '/hub',
    text: 'Hub Dashboard',
    group: RouteGroup.HUB,
    showSearch: false,
    showWarehouseSelector: true,
    roles: [UserRole.TECH, UserRole.OPS_ADMIN, UserRole.OPS_OPERATOR, UserRole.OPS_WAREHOUSE],
  },
  {
    icon: <MdSystemUpdateAlt />,
    path: '/inbound',
    text: 'Inbound',
    group: RouteGroup.HUB,
    showSearch: false,
    featureFlag: 'ENABLE_INBOUD_TRIAGE' as const,
    roles: [UserRole.TECH, UserRole.OPS_ADMIN, UserRole.OPS_OPERATOR, UserRole.OPS_WAREHOUSE],
  },
  {
    icon: <MdOutbox />,
    path: '/outbound',
    text: 'Outbound',
    group: RouteGroup.HUB,
    showSearch: false,
    featureFlag: 'ENABLE_OUTBOUND' as const,
    roles: [UserRole.TECH, UserRole.OPS_ADMIN, UserRole.OPS_OPERATOR, UserRole.OPS_WAREHOUSE],
  },
  {
    icon: <MdBuild />,
    path: '/service-outbound',
    text: 'Service outbounBd',
    group: RouteGroup.HUB,
    showSearch: false,
    showWarehouseSelector: true,
    featureFlag: 'ENABLE_BRIDGE_SERVICE_OUTBOUND' as const,
    roles: [UserRole.TECH, UserRole.OPS_ADMIN, UserRole.OPS_OPERATOR, UserRole.OPS_WAREHOUSE],
  },
  {
    icon: <MdBuild />,
    path: '/service-inbound',
    text: 'Service inbound',
    group: RouteGroup.HUB,
    showSearch: false,
    showWarehouseSelector: true,
    featureFlag: 'ENABLE_SERVICE_INBOUND' as const,
    roles: [UserRole.TECH, UserRole.OPS_ADMIN, UserRole.OPS_OPERATOR, UserRole.OPS_WAREHOUSE],
  },
]

const routes: Route[] = [
  {
    icon: <MdShoppingCart />,
    path: '/orders',
    text: 'Orders',
    group: RouteGroup.TOOLS,
    showSearch: true,
    roles: [
      UserRole.TECH,
      UserRole.OPS_ADMIN,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
    ],
  },
  {
    icon: <MdTrolley />,
    path: '/item/location',
    text: 'Item lookup / location',
    group: RouteGroup.TOOLS,
    showSearch: false,
    showWarehouseSelector: true,
    featureFlag: 'ENABLE_BRIDGE_PUT_AWAY' as const,
    roles: [
      UserRole.TECH,
      UserRole.OPS_ADMIN,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
    ],
  },
  {
    icon: <MdTrolley />,
    path: '/item/location/vinden',
    text: 'Vinden scanner',
    group: RouteGroup.TOOLS,
    showSearch: false,
    showWarehouseSelector: true,
    featureFlag: 'ENABLE_BRIDGE_PUT_AWAY' as const,
    roles: [UserRole.TECH, UserRole.OPS_ADMIN, UserRole.OPS_OPERATOR, UserRole.OPS_WAREHOUSE],
  },
  {
    icon: <MdOutlineOpenInNew />,
    path: '/picking',
    text: 'Picking',
    group: RouteGroup.WAREHOUSE,
    showSearch: false,
    featureFlag: 'ENABLE_BRIDGE_PICKING' as const,
    roles: [UserRole.TECH, UserRole.OPS_ADMIN, UserRole.OPS_OPERATOR, UserRole.OPS_WAREHOUSE],
  },
  {
    icon: <MdOutlineOpenInNew />,
    path: '/picking-in-warehouse',
    text: 'Picking in Warehouse',
    group: RouteGroup.WAREHOUSE,
    showSearch: false,
    showWarehouseSelector: true,
    featureFlag: 'ENABLE_BRIDGE_PICKING_R1' as const,
    roles: [UserRole.TECH, UserRole.OPS_ADMIN, UserRole.OPS_OPERATOR, UserRole.OPS_WAREHOUSE],
  },
  {
    icon: <MdCheckBoxOutlineBlank />,
    path: '/quarantine',
    text: 'Quarantine',
    group: RouteGroup.TOOLS,
    showSearch: false,
    featureFlag: 'ENABLE_BRIDGE_QUARANTINE' as const,
    roles: [UserRole.TECH, UserRole.OPS_ADMIN, UserRole.OPS_OPERATOR, UserRole.OPS_WAREHOUSE],
  },
  {
    icon: <MdDirectionsCar />,
    path: '/routes',
    text: 'Routes',
    group: RouteGroup.DRIVER,
    showSearch: false,
    roles: [
      UserRole.TECH,
      UserRole.OPS_ADMIN,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
      UserRole.OPS_EXTERNAL_DRIVER,
    ],
  },
  {
    icon: <MdForklift />,
    path: '/item/location/bulk',
    text: 'Bulk location update',
    group: RouteGroup.TOOLS,
    showSearch: false,
    showWarehouseSelector: true,
    featureFlag: 'ENABLE_BULK_LOCATION_UPDATE' as const,
    roles: [
      UserRole.TECH,
      UserRole.OPS_ADMIN,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
    ],
  },
  {
    icon: <MdShelves />,
    path: '/item/inventory',
    text: 'Inventory count',
    group: RouteGroup.TOOLS,
    showSearch: false,
    showWarehouseSelector: true,
    featureFlag: 'ENABLE_INVENTORY_VIEW' as const,
    roles: [UserRole.TECH, UserRole.OPS_ADMIN, UserRole.OPS_OPERATOR, UserRole.OPS_WAREHOUSE],
  },

  {
    icon: <MdErrorOutline />,
    path: 'https://forms.gle/pH3k3AJHAgjXApzV9',
    text: 'Report Item Issue',
    group: RouteGroup.TOOLS,
    showSearch: false,
    showWarehouseSelector: true,
    roles: [
      UserRole.TECH,
      UserRole.OPS_ADMIN,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
      UserRole.OPS_EXTERNAL_DRIVER,
    ],
  },
  {
    icon: <MdSystemUpdateAlt />,
    path: '/inbound-warehouse',
    text: 'Inbound Warehouse',
    group: RouteGroup.WAREHOUSE,
    showSearch: false,
    featureFlag: 'ENABLE_CONSIGNMENTS' as const,
    showWarehouseSelector: true,
    roles: [UserRole.TECH, UserRole.OPS_ADMIN, UserRole.OPS_OPERATOR, UserRole.OPS_WAREHOUSE],
  },
  {
    icon: <MdOutlineWarehouse />,
    path: '/putaway-warehouse',
    text: 'Putaway Warehouse',
    group: RouteGroup.WAREHOUSE,
    showSearch: false,
    featureFlag: 'ENABLE_PUTWAY_WAREHOUSE' as const,
    showWarehouseSelector: true,
    roles: [UserRole.TECH, UserRole.OPS_ADMIN, UserRole.OPS_OPERATOR, UserRole.OPS_WAREHOUSE],
  },
  ...hubRoutes,
]

const menuItemSort = sortWith<Route>([
  ascend(
    (route) => routeSortOrder.get(propOr(RouteGroup.OTHERS, 'group', route)) ?? routeSortOrder.size,
  ),
  ascend(prop('text')),
])

const menuItems: (featureFlags: Record<FeatureFlags, boolean>, roles: UserRole[]) => Route[] = (
  featureFlags,
  roles,
) =>
  [home, ...menuItemSort(routes)].filter((item) => {
    const flagCheck = !item.featureFlag || featureFlags[item.featureFlag]
    const roleCheck = item.roles.length === 0 || roles.some((role) => item.roles.includes(role))
    return flagCheck && roleCheck
  })

type HeaderProps =
  | { children: React.ReactNode; title?: undefined }
  | { title: string | undefined; children?: undefined }
  | { children?: undefined; title?: undefined }

export function Header({ title }: HeaderProps) {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { featureFlags, isInitialLoading: isLoadingFeatureFlags } = useFeatureFlags({
    suspense: false,
  })
  const { logout } = useLogout()
  const { data: account, isInitialLoading: isLoadingUser } = useCurrentAccount()
  const queryClient = useQueryClient()
  const { colorMode, toggleColorMode } = useColorMode()
  const menuTextColor = useColorModeValue('black', 'white')
  const city = useAppSelector((state) => state.filters.city)
  const roles = useRoles()
  const toast = useToast()

  useEffect(() => {
    if (account?.city && account?.city !== city) {
      if (city !== 'all') {
        toast({
          title: 'City changed',
          description: `Your city has been changed from ${formatCity(city)} to ${formatCity(
            account?.city,
          )}. If you mostly work in ${formatCity(
            city,
          )}, you can ask in #tech-support on slack to get your user moved.`,
          status: 'info',
          duration: null,
          isClosable: true,
        })
      }
      dispatch(setCity(account?.city))
      dispatch(setWarehouse('all'))
      dispatch(setOrdersPage(1))
      dispatch(setRoutesPage(1))
    }
  }, [account?.city])

  const getNameOfUser = (user?: UserV2Dto | AccountDto) => {
    if (user?.firstName && user?.lastName) {
      return `${user.firstName} ${user.lastName}`
    }
    return undefined
  }

  const current = sortBy((item) => -item.path.length, menuItems(featureFlags, roles)).find((item) =>
    pathname.startsWith(item.path),
  )

  const groupedMenuItems = Object.entries(
    groupBy(propOr(RouteGroup.OTHERS, 'group'), menuItems(featureFlags, roles)),
  )

  return (
    <Box backgroundColor="purple.500" color="white">
      <Flex justifyContent="space-between" alignItems="center" height={['48px', '52px']}>
        <Flex alignItems="center" ml="2" mr="2" height="100%">
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<Icon as={MdMenu} />}
              variant="ghost"
              fontSize="large"
              mr="2"
              color="white"
              _hover={{ color: 'blackAlpha.800' }}
              _active={{ color: 'blackAlpha.800' }}
            />
            <MenuList color={menuTextColor} zIndex={200}>
              {isLoadingFeatureFlags ? (
                <Loader />
              ) : (
                groupedMenuItems.map(([groupName, items]) => {
                  return groupName !== RouteGroup.OTHERS ? (
                    <React.Fragment key={groupName}>
                      <MenuDivider />
                      <MenuGroup title={sentenceCase(groupName)}>
                        <WandaMenuItems items={items} />
                      </MenuGroup>
                    </React.Fragment>
                  ) : (
                    <WandaMenuItems key={groupName} items={items} />
                  )
                })
              )}
              <MenuDivider />
              <MenuItem onClick={toggleColorMode}>
                Toggle {colorMode === 'light' ? 'dark' : 'light'} mode
              </MenuItem>
              <MenuItem
                display="inline-block"
                onClick={() => {
                  logout()
                  queryClient.invalidateQueries(['items'])
                }}
              >
                <Text as="span" fontSize="small">
                  Logged in as {account?.email || getNameOfUser(account)}
                </Text>
                <br />
                Log out
              </MenuItem>
            </MenuList>
          </Menu>
          <Text as="span" fontSize="large" fontWeight="semibold">
            {title || current?.text}
          </Text>
        </Flex>
        <Box mr={[0, 2]}>
          <Suspense fallback={<Loader />}>
            {isLoadingUser ? <Loader /> : <CityWarehouseSelector route={current} />}
          </Suspense>
        </Box>
      </Flex>
    </Box>
  )
}

const CityWarehouseSelector = requireRoles(
  {
    requireRoles: [
      UserRole.TECH,
      UserRole.ADMIN,
      UserRole.OPS_ADMIN,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
    ],
    hideIfMissing: true,
  },
  function CityWarehouseSelector({ route }: { route?: Route }) {
    const color = useColorModeValue('gray.600', 'gray.300')
    const groupColor = useColorModeValue('purple.500', 'purple.300')
    const filters = useAppSelector((state) => state.filters)
    const dispatch = useDispatch()
    const warehouses = useWarehouses()
    const warehousesById = indexBy(prop('id'), warehouses.data?.items ?? [])
    const warehousesByCity = groupBy(prop('city'), warehouses.data?.items ?? [])
    const cities = values(SupportedCities)
    const city = filters.city === 'all' ? 'All cities' : formatCity(filters.city)
    const warehouse = filters.warehouse === 'all' ? city : warehousesById[filters.warehouse]?.name
    return (
      <Menu>
        <MenuButton as={Button} variant="ghost" mr="2" color="white">
          {route?.showWarehouseSelector ? warehouse : city}
        </MenuButton>

        {route?.showWarehouseSelector ? (
          <MenuList color={color} zIndex={200}>
            {cities.map((city, index) => (
              <Fragment key={city}>
                <MenuGroup
                  key={city}
                  title={formatCity(city as SupportedCities)}
                  color={groupColor}
                >
                  {warehousesByCity[city]?.length > 1 && (
                    <MenuItem
                      fontWeight={
                        filters.warehouse === 'all' && city === filters.city ? 'bold' : undefined
                      }
                      onClick={() => {
                        dispatch(setWarehouse('all'))
                        dispatch(setCity(city as string))
                        dispatch(setOrdersPage(1))
                        dispatch(setRoutesPage(1))
                      }}
                    >
                      All
                    </MenuItem>
                  )}
                  {warehousesByCity[city]?.map((warehouse) => (
                    <MenuItem
                      key={warehouse.id}
                      fontWeight={warehouse.id === filters.warehouse ? 'bold' : undefined}
                      onClick={() => {
                        dispatch(setWarehouse(warehouse.id))
                        dispatch(setCity(city as string))
                        dispatch(setOrdersPage(1))
                        dispatch(setRoutesPage(1))
                      }}
                    >
                      {warehouse.name}
                    </MenuItem>
                  ))}
                </MenuGroup>
                {index < cities.length - 1 && <MenuDivider />}
              </Fragment>
            ))}
          </MenuList>
        ) : (
          <MenuList color={color} zIndex={200}>
            {['all', ...values(SupportedCities)].map((city) => (
              <MenuItem
                key={city}
                onClick={() => {
                  dispatch(setCity(city as string))
                  dispatch(setWarehouse('all'))
                  dispatch(setOrdersPage(1))
                  dispatch(setRoutesPage(1))
                }}
              >
                {city === 'all' ? 'All cities' : formatCity(city as SupportedCities)}
              </MenuItem>
            ))}
          </MenuList>
        )}
      </Menu>
    )
  },
)

export const WandaMenuItems: React.FC<{ items: Route[] }> = ({ items }) => {
  return (
    <>
      {items.map((item) =>
        item.path.startsWith('http') ? (
          <MenuItem
            key={item.path}
            as="a"
            href={item.path}
            target="_blank"
            referrerPolicy="no-referrer"
          >
            <Flex alignItems="center" gap={1}>
              {item.icon}
              {item.text}
            </Flex>
          </MenuItem>
        ) : (
          <MenuItem key={item.path} as={Link} to={item.path}>
            <Flex alignItems="center" gap={1}>
              {item.icon}
              {item.text}
            </Flex>
          </MenuItem>
        ),
      )}
    </>
  )
}
