import { Badge, HStack, Text } from '@chakra-ui/react'
import React from 'react'

import type { OrderType } from '@wanda-space/types'

import type { OrderAdminResponseDto } from '../api-client/types-old'
import { useOrders } from '../hooks'
import { OrderLink } from './OrderLink'

export function OrderBooking({
  orderId,
  type,
  showDetails = false,
}: {
  orderId: string
  type?: OrderType
  showDetails?: boolean
  showSimpleId?: boolean
}) {
  const { data, isSuccess } = useOrders({
    filters: { id: orderId, type },
  })

  const order: OrderAdminResponseDto | undefined = data?.items[0]

  if (isSuccess && order) {
    const badge = (
      <Badge colorScheme="green">
        <Text>{order.orderDetails.deliveryDate}</Text>
      </Badge>
    )
    return !showDetails ? (
      badge
    ) : (
      <HStack>
        <OrderLink orderId={orderId} />
        {badge}
      </HStack>
    )
  }
  return null
}
