import React from 'react'
import { Link } from 'react-router-dom'

import { useAccount } from '../hooks/useAccount'

export function AccountLink({
  id,
  showId,
  showName = true,
  title,
  newTab,
}: {
  id: string
  showId?: boolean
  showName?: boolean
  title?: string
  newTab?: boolean
}) {
  const { data: account, isSuccess } = useAccount(id, { enabled: showName, refetchOnMount: false })
  let text = id
  if (isSuccess) {
    let name = ''
    if (showName) {
      if (account.companyName) {
        name = account.companyName
      } else if (account.firstName || account.lastName) {
        name = `${account.firstName || ''} ${account.lastName || ''}`
      } else if (account.address?.firstName || account.address?.lastName) {
        name = `${account.address.firstName || ''} ${account.address.lastName || ''}`
      } else {
        name = account.email
      }
    }
    if (showId && showName) {
      text = `${name} (${id})`
    } else if (showName) {
      text = `${name}`
    }
  }
  return (
    <Link
      target={newTab ? '_blank' : '_self'}
      style={{ textDecoration: 'underline' }}
      to={`/accounts/${id}`}
      title={title}
    >
      {text}
    </Link>
  )
}
