import { Badge, Box, Button, Flex, Grid, HStack, Stack, Text } from '@chakra-ui/react'
import type { Destination, InboundItemDto, ItemDto } from '@wanda-space/ops-types'
import type { Product } from '@wanda-space/ops-types'
import { Warehouse, WarehouseLocation } from '@wanda/shared/src'
import ImagePreview from 'components/ImagePreview'
import { Card } from 'components/ui/Card/Card'
import React from 'react'
import { FormattedMessage } from 'react-intl'

function hasDestination(
  item: ItemDto | InboundItemDto,
): item is InboundItemDto & { destination: Destination } {
  return (
    (item as unknown as InboundItemDto).destination !== undefined &&
    (item as unknown as InboundItemDto).destination !== null
  )
}

export const ItemCard = ({
  item,
  addons = [],
  imageSize = '140px',
  onClickUpdate,
  onShowMore,
}: {
  item: ItemDto | InboundItemDto
  addons?: Product[]
  imageSize?: string
  onClickUpdate?: () => void
  onShowMore?: () => void
}) => {
  // @ts-ignore TODO: Fix ops types
  const hasService = item.pendingServices?.length > 0
  return (
    <Card key={item.id} display="flex" flexDirection="column" gap={4} p={4} overflow="hidden">
      <Flex gap="3">
        <ImagePreview itemId={item.id} fromOrderItems imageSize={imageSize} />
        <Stack>
          <Text size="2xl" overflowWrap="anywhere" flexShrink="100">
            {item.name}
          </Text>
          <HStack mb={4}>
            <Badge>{item.type}</Badge>
            {item.opsType ? <Badge>{item.opsType}</Badge> : undefined}
            <Badge colorScheme="purple" variant="solid">
              {item.state.replaceAll('_', ' ')}
            </Badge>
            {item.listingId && <Badge colorScheme="green">Listing</Badge>}
            {hasService && <Badge colorScheme="orange">Pending service</Badge>}
          </HStack>
        </Stack>
      </Flex>

      <Flex direction="column" gap={2}>
        <Grid templateColumns="repeat(2, 50%)" templateRows="repeat(2, auto)" gap="1">
          {item.storageProduct.localizationKey && <Text color="gray.500">PRODUCT</Text>}
          <Text color="gray.500">SIMPLE ID</Text>
          {item.storageProduct.localizationKey && (
            <Text>
              <FormattedMessage id={`${item.storageProduct.localizationKey}.name`} />
            </Text>
          )}
          <Text>{item.simpleId}</Text>
        </Grid>
        {item.location && (
          <Grid templateColumns="repeat(2, 50%)" templateRows="repeat(2, auto)">
            {!!item.warehouse && <Text color="gray.500">WAREHOUSE</Text>}
            {!!item.location && <Text color="gray.500">LOCATION</Text>}
            {!!item.warehouse && (
              <Text>
                <Warehouse id={item.warehouse.id} />
              </Text>
            )}
            {!!item.location && (
              <Text>
                <WarehouseLocation id={item.location.id} />
              </Text>
            )}
          </Grid>
        )}

        {hasDestination(item) && (
          <Box>
            <Text color="gray.500">NEXT LOCATION</Text>
            <Text>
              <WarehouseLocation id={item.destination.id} />
            </Text>
          </Box>
        )}

        {addons.length > 0 && (
          <>
            <Text color="gray.500" mt={2}>
              ADD ONS
            </Text>
            {addons?.map((product) => (
              <Text key={product.id} overflowWrap="anywhere">
                {product.name}
              </Text>
            ))}
          </>
        )}
      </Flex>
      <Flex justifyContent="space-between">
        {onClickUpdate && !hasService && (
          <Button variant="link" onClick={onClickUpdate}>
            Update details
          </Button>
        )}
        {onShowMore && (
          <Button variant="link" onClick={onShowMore}>
            Show more
          </Button>
        )}
      </Flex>
    </Card>
  )
}
