import type { InboundItemDto, ItemDto, StorageProduct } from '@wanda-space/ops-types'
import type { State, Type } from '@wanda-space/ops-types/dist/ItemDto'
import type { AccountId, WandaId } from '@wanda-space/types'
import type { HandoverItem } from '@wanda/shared'
import { request } from '@wanda/shared/src/api-client/api-client'
import { type ApiFilters, fetchChunk } from '@wanda/shared/src/api-client/chunk'
import type { Product } from '@wanda/shared/src/api-client/routes/products'
import type { StorageItemState } from '@wanda/shared/src/consts/enums'

import { opsBaseUrl } from 'api/config'
import type { StorageItemType, SupportedCities, SupportedCountries } from 'consts'
import type { HistoryObject } from 'interface'
import { mapItemToHandover } from 'utils/handover'

export function fetchOpsItems(params: {
  orderBy?: string
  orderDir?: 'ASC' | 'DESC'
  itemsPerPage?: number
  page?: number
  search?: string | undefined
  filters?: ApiFilters | undefined
  token: string
}): Promise<{ items: InboundItemDto[]; count: number }> {
  return fetchChunk({
    baseUrl: opsBaseUrl,
    url: '/v1/items/',
    orderBy: 'simpleId',
    orderDir: 'ASC',
    page: params.page || 1,
    itemsPerPage: 100,
    search: undefined,
    filters: undefined,
    ...params,
  })
}

export async function fetchOpsItemsByOrder({
  token,
  orderId,
}: {
  token: string
  orderId: string
}): Promise<ItemDto[]> {
  return (
    await request<{ items: ItemDto[]; count: number }>({
      baseUrl: opsBaseUrl,
      method: 'GET',
      url: `/v1/items/by-order/${orderId}/`,
      token,
    })
  ).items
}

export function fetchItemsInQuarantine(params: {
  city?: SupportedCities
  token: string
}): Promise<Record<string, ItemDto[]>> {
  return request({
    baseUrl: opsBaseUrl,
    method: 'GET',
    url: '/v1/items/quarantine/',
    token: params.token,
    params: new URLSearchParams({ city: params.city! }),
  })
}

export const fetchOpsItem = async (itemId: string, token: string): Promise<InboundItemDto> => {
  const url = `v1/items/${itemId}/`

  return await request({
    method: 'GET',
    url,
    token,
    baseUrl: opsBaseUrl,
  })
}

export const getListOfImages = async (itemId: string, token: string): Promise<string[]> => {
  try {
    return await request({
      method: 'GET',
      url: `items/${itemId}/images`,
      token,
    })
  } catch (err) {
    console.error(`Cannot fetch list of images for item: ${err}`)
    throw err
  }
}

export const deleteImage = async (
  itemId: string,
  imageId: string,
  token: string,
): Promise<string[]> => {
  try {
    return await request({
      method: 'DELETE',
      url: `items/${itemId}/image/${imageId}`,
      token,
    })
  } catch (err) {
    console.error(`cound not delete image: ${err}`)
    throw err
  }
}

export const uploadImage = async ({
  itemId,
  file,
  token,
}: {
  itemId: string
  token: string
  file: string
}): Promise<{ imageId: string }> => {
  const form = new FormData()

  if (file) {
    const img = await fetch(file)
    const imgBlob = await img.blob()
    form.append('file', imgBlob)
  }

  try {
    return await request({
      method: 'POST',
      url: `items/${itemId}/image`,
      body: form,
      token: token,
    })
  } catch (err) {
    console.error(`Cannot upload image: ${err}`)
    throw err
  }
}

interface StorageItem {
  id: string
  name: string
  description?: string
  image: string
  createdAt: string
  updatedAt: string
  deletedAt?: string
  type: StorageItemType
  state: StorageItemState
  ownerId: string
  simpleId: number
  pendingOrderId?: string
  currentlyWith?: string
  city: SupportedCities
  countryCode: SupportedCountries
  storageProduct: Product
}

export const createItem = async ({
  name,
  type,
  token,
  ownerId,
  orderId,
  state,
  file,
}: {
  orderId?: string
  ownerId: string
  token: string
  state?: StorageItemState | State
  name: string
  type: StorageItemType | Type
  file?: string
}): Promise<HandoverItem> => {
  const paramParams: { ownerId: string; orderId?: string } = {
    ownerId,
  }
  if (orderId) {
    paramParams.orderId = orderId
  }
  const params = new URLSearchParams(paramParams)
  const form = new FormData()
  if (state) {
    form.append('state', state)
  }
  form.append('name', name)
  form.append('type', type)

  if (file) {
    const img = await fetch(file)
    const imgBlob = await img.blob()
    form.append('file', imgBlob)
  }

  const item = await request<StorageItem>({
    method: 'POST',
    url: '/admin/item',
    body: form,
    params,
    token,
  })
  return mapItemToHandover(item)
}

export const updateItem = async ({
  itemId,
  name,
  type,
  token,
  ownerId,
  state,
  storageProduct,
}: {
  itemId: string
  ownerId: string
  token: string
  state: StorageItemState | State
  name: string
  type: StorageItemType | Type
  storageProduct?: StorageProduct
}): Promise<HandoverItem> => {
  const params = new URLSearchParams({
    ownerId,
  })

  await request({
    method: 'PUT',
    url: `/admin/item/${itemId}`,
    params,
    body: { state: state, type: type, name: name, storageProduct },
    token,
  })
  return mapItemToHandover(await fetchOpsItem(itemId, token))
}

export const fetchCustomerItems = async ({
  token,
  accountId,
}: { token: string; accountId: AccountId }) => {
  return fetchOpsItems({
    token,
    page: 1,
    itemsPerPage: 1000,
    filters: { ownerId: accountId },
  })
}

export const updateItemLocation = async ({
  itemId,
  location,
  token,
}: {
  itemId: string
  location: string
  token: string
}) => {
  return await request({
    method: 'POST',
    url: `/v1/items/${itemId}/location/`,
    body: {
      id: itemId,
      location,
    },
    token,
    baseUrl: opsBaseUrl,
  })
}

export function fetchItemOpsHistory(
  itemId: string,
  token: string,
): Promise<{ count: number; items: HistoryObject<StorageItem>[] }> {
  return request({
    method: 'GET',
    url: `/v1/items/${itemId}/history/`,
    token,
    baseUrl: opsBaseUrl,
  })
}
