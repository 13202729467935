import type { ItemImageResponseDto, PaginatedResponseDto } from '@wanda-space/types'
import { request, requestBlob } from '../api-client'
import { type ApiFilters, fetchChunk } from '../chunk'
import type { OrderAddressDto } from '../types-old'

export interface ServiceProviderRequestDTO {
  name: string
  localizationKey: string
  active: boolean
  tags: string[]
  address: OrderAddressDto
  email: string
  mobilePhone: string
  contactPerson: {
    phoneNumber: string
    name: string
  }
  companyName: string
}

export interface ServiceProvider {
  id: string
  deletedAt: string
  createdAt: string
  updatedAt: string
  name: string
  localizationKey: string
  active: boolean
  tags: string[]
  contactInfo: {
    address: OrderAddressDto
    legalCompanyName: string
    email: string
    mobilePhone: string
    contactPerson: {
      phoneNumber: string
      name: string
    }
  }
}

export async function fetchServiceProviders(params: {
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: ApiFilters | undefined
  token: string
}): Promise<{ items: ServiceProvider[]; count: number }> {
  return fetchChunk({
    url: '/admin/service-providers',
    ...params,
    search: params.search?.trim(),
  })
}

export async function fetchServiceProvider(id: string, token: string): Promise<ServiceProvider> {
  return request({
    method: 'GET',
    url: `/service-providers/${id}`,
    token,
  })
}

export function uploadServiceProviderImage(
  serviceProviderId: string,
  form: FormData,
  token: string,
): Promise<string> {
  return request({
    method: 'POST',
    url: `admin/service-providers/${serviceProviderId}/image`,
    body: form,
    token,
  })
}

export async function createServiceProvider(
  values: ServiceProviderRequestDTO,
  token: string,
): Promise<ServiceProvider> {
  return await request({
    url: '/admin/service-providers/',
    method: 'POST',
    token,
    body: values,
  })
}

export async function updateServiceProvider(
  id: string,
  values: ServiceProviderRequestDTO,
  token: string,
): Promise<ServiceProvider> {
  return await request({
    url: `/admin/service-providers/${id}`,
    method: 'PUT',
    token,
    body: values,
  })
}

export async function deleteServiceProviderImage(
  serviceProviderId: string,
  imageId: string,
  token: string,
): Promise<void> {
  return request({
    method: 'DELETE',
    url: `admin/service-providers/${serviceProviderId}/image/${imageId}`,
    token,
  })
}

export async function fetchServiceProviderImageDetails(id: string, token: string) {
  return request<PaginatedResponseDto<ItemImageResponseDto>>({
    method: 'GET',
    url: `admin/service-providers/${id}/image-details`,
    token,
  })
}

export async function fetchServiceProviderImageDetail(
  serviceProviderId: string,
  imageId: string,
  token: string,
) {
  return request<ItemImageResponseDto>({
    method: 'GET',
    url: `admin/service-providers/${serviceProviderId}/image-detail/${imageId}`,
    token,
  })
}
